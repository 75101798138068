import React, { FC } from 'react'
import styles from './Service.module.scss'
import ServiceList from './ServiceList'

const imgPic = require('../../shared/pic1.webp')

const plumbingList = [
    'Plumbing repair',
    'toilet repair & replacement',
    'Faucet repair & replacement',
    'Piping installation',
    'Frozen pipe repair',
    'Kitchen & bathroom reapair',
    'Kitchen & bathroom installation',
    'Bathroom remodel',
    'Pipe repair & replacement',
    'Stack repair & replacement'
]

const drainList = [
    'Drain cleaning',
    'rooter service',
    'hydro jetting',
    'water line repair',
    'water line installation',
    'sewer line repair',
    'sewer line installation'
]

const sumpList = [
    'sump pump installation'
]

const Service: FC = () => {
    return (
        <div className={styles.service}>
            <h2>Plumbing Service</h2>
            <div className={styles.wrapper}>
                <div className={styles.serviceColumn}>
                    <div className={styles.serviceGrid}>
                        <ServiceList title='Plumbing Service' list={plumbingList} />
                        <div className={styles.gridColumn}>
                            <ServiceList title='Drain and Sewer' list={drainList} />
                            <ServiceList title='sump pumps' list={sumpList} />
                        </div>
                    </div>
                </div>
                <div className={styles.serviceImg}>
                    <div className={styles.imgWrapper}>
                        <img src={imgPic} alt="" className={styles.img} loading='lazy' />
                    </div>
                    <div className={styles.rectangle} ></div>
                </div>
            </div>
        </div>
    )
}

export default Service;