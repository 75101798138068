import React, { FC } from 'react'
import styles from './CallNow.module.scss'

const CallNow: FC = () => {
    return (
        <div className={styles.call}>
            <a className={styles.a} href="tel:+14164753474"> 
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 0C10.0277 0 7.61099 0.733112 5.55538 2.10663C3.49976 3.48015 1.89761 5.43238 0.951511 7.71646C0.00541626 10.0005 -0.242126 12.5139 0.240189 14.9386C0.722505 17.3634 1.91301 19.5907 3.66117 21.3388C5.40933 23.087 7.63661 24.2775 10.0614 24.7598C12.4861 25.2421 14.9995 24.9946 17.2835 24.0485C19.5676 23.1024 21.5199 21.5002 22.8934 19.4446C24.2669 17.389 25 14.9723 25 12.5C25 9.18479 23.683 6.00537 21.3388 3.66116C18.9946 1.31696 15.8152 0 12.5 0M19.1396 18.4021L18.1156 19.4323C17.8901 19.5833 17.6352 19.6852 17.3677 19.7312C15.7533 19.7438 14.1525 19.4345 12.659 18.8213C11.1654 18.2082 9.80907 17.3035 8.66915 16.1602C7.52923 15.0169 6.6286 13.6578 6.01989 12.1625C5.41119 10.6671 5.10661 9.06544 5.12396 7.45104C5.17539 7.19897 5.27707 6.95984 5.42292 6.74792L6.45521 5.71562C6.6688 5.52174 6.92822 5.3854 7.20904 5.31944C7.48986 5.25347 7.78284 5.26004 8.06042 5.33854L8.27813 5.40521C8.5521 5.51663 8.79871 5.68606 9.001 5.90183C9.20329 6.11761 9.35646 6.37462 9.45 6.65521L9.97084 8.55312C10.0327 8.83805 10.0272 9.13348 9.95488 9.41593C9.88255 9.69838 9.74535 9.96007 9.55417 10.1802L8.86459 10.8667C9.19668 12.096 9.84492 13.2169 10.7448 14.1179C11.6447 15.0189 12.7648 15.6685 13.9938 16.0021L14.6833 15.3156C14.9023 15.1212 15.164 14.981 15.4472 14.9062C15.7303 14.8315 16.0271 14.8244 16.3135 14.8854L18.2146 15.4062C18.494 15.5014 18.7495 15.656 18.9635 15.8594C19.1775 16.0628 19.3449 16.3101 19.4542 16.5844L19.5292 16.8052C19.605 17.0829 19.6098 17.3752 19.543 17.6552C19.4762 17.9351 19.34 18.1938 19.1469 18.4073" fill="#3B8DCB"/>
                </svg>
                416-475-3474
            </a>
        </div>
    )
}

export default CallNow;