import React, { FC } from 'react'
import styles from './AboutBlock.module.scss'

interface AboutBlockProps {
    imageSrc: string,
    text: string,
}

const AboutBlock: FC<AboutBlockProps> = ({
    imageSrc,
    text
}) => {
    return (
        <div className={styles.aboutblock}>
            <div className={styles.imgWrapper}>
                <img src={imageSrc} alt="" className={styles.img} loading='lazy' />
            </div>
            <p className={styles.text}>{text}</p>
        </div>
    )
}

export default AboutBlock;