import React, { FC } from 'react'
import styles from './Serving.module.scss'
import ServingItem from './ServingItem'

const imgPic = require('../../shared/pic2.webp')

const servingArea = [
    'Toronto, ON',
    'Missisauga, ON',
    'Etobicoke, ON',
    'Downtown, ON',
    'East York, ON',
    'North York, ON',
    'Scarborough, ON',
    'Vaughan, ON',
    'Richmond Hill, ON',
    'Markham, ON',
    'Maple, ON'
]

const Serving: FC = () => {
    return (
        <div className={styles.serving}>
            <h2 className={styles.h2}>SERVING ARE</h2>
            <div className={styles.servingBlock}>
                <div className={styles.servingMain}>
                    <div className={styles.servingList}>
                        {
                            servingArea.map((item, index) => (
                                <ServingItem key={index} location={item} />
                            ))
                        }
                    </div>
                </div>
                <div className={styles.servingImg}>
                    <div className={styles.imgWrapper}>
                        <img src={imgPic} alt="" className={styles.img} loading='lazy' />
                    </div>
                    <div className={styles.rectangle} ></div>
                </div>
            </div>
        </div>
    )
}

export default Serving;