import React, { FC } from 'react'
import styles from './Landing.module.scss'
import Nav from '../Nav/Nav'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import ContactForm from '../ContactForm/ContactForm'
import About from '../About/About'
import Serving from '../Serving/Serving'
import Service from '../Service/Service'
import Reviews from '../Reviews/Reviews'

const Landing: FC = () => {
    return (
        <div className={styles.landing}>
            <Nav />
            <Header />
            <About />
            <Service />
            <Reviews />
            <Serving />
            <ContactForm />
            <Footer />
        </div>
    )
}

export default Landing;