import React, { FC, useEffect, useState } from 'react'
import styles from './ContactForm.module.scss'

const ContactForm: FC = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [message, setMessage] = useState('')

    const [success, setSuccess] = useState(0)

    const [active, setActive] = useState(false)

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    useEffect(() => {
        if (localStorage.getItem('isSended')) {
            const prev = localStorage.getItem('isSended')
            const delta = Date.now() - Number(prev)
            if (delta >= 5*60*1000) {
                setActive(true)
            }
        } else {
            setActive(true)
        }
    }, [])

    const hadnleNumber = (value : string) => {
        value = value.replace(/\D+/gm, '');

        let num = `${value.substring(0, 3)} ${value.substring(3, 6)} ${value.substring(6, 10)}`
        num = num.trim()
        setNumber(num)
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFiles(Array.from(event.target.files));
        }
    };

    const handleSubmit = async (e : React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!active) {
            return
        }
        const data = new FormData();

        selectedFiles.forEach((file, index) => {
            data.append(`files`, file)
        })

        data.append('name', name)
        data.append('email', email)
        data.append('number', number)
        data.append('message', message)
        try {
            let response = await fetch('https://tjplumbing.ca:5000/api/form', {
                method: 'POST',
                body: data
            })
            if (response.ok) {
                setSuccess(1)
                if (localStorage.getItem('isSended')) {
                    localStorage.removeItem('isSended')
                }
                localStorage.setItem('isSended', JSON.stringify(Date.now()))
                setActive(false)
            } else {
                setSuccess(2)
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={styles.contactWrapper}>
            <div className={styles.contact}>
                <h2 id='contact_form' className={styles.titleContact}>CONTACT US</h2>
                <form method='post' className={styles.contactForm} onSubmit={handleSubmit} encType='multipart/form-data' >
                    <div className={styles.field}>
                        <label htmlFor="name" className={styles.label} >Name</label>
                        <input type="text" name="name" placeholder='John Doe' className={styles.input} value={name} onChange={e => setName(e.target.value)} />
                    </div>

                    <div className={styles.field}>
                        <label htmlFor="tel" className={styles.label} >Phone number</label>
                        <input 
                            type="tel"
                            name="tel"
                            placeholder='416 000 0000'
                            className={styles.input}
                            required
                            pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                            value={number}
                            onChange={e => hadnleNumber(e.target.value)}                
                        />
                    </div>

                    <div className={styles.field}>
                        <label htmlFor="email" className={styles.label} >Email</label>
                        <input
                            type="email"
                            name="email"
                            required
                            placeholder='example@domain.com'
                            className={styles.input}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />    
                    </div>

                    <div className={`${styles.field} ${styles.fieldArea}`}>
                        <label htmlFor="textarea" className={styles.label} >Message</label>
                        <textarea name="textarea" placeholder='Write your message...' className={`${styles.input} ${styles.inputArea}`} value={message} onChange={e => setMessage(e.target.value)} ></textarea>
                    </div>

                    <input type="file" name="files" multiple onChange={handleFileChange} accept="image/*" className={styles.inputFile} />

                    {active ? 
                        <button className={styles.button} >SEND</button>
                        :
                        <button className={styles.button} disabled>SEND</button>
                    }
                </form>
            </div>
        </div>
    )
}

export default ContactForm;