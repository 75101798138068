import React, { FC, useEffect, useState } from 'react'
import styles from './Nav.module.scss'
import { LogoSVGComponent } from '../../shared/LogoSVGComponent';
import CallNow from './CallNow/CallNow';

const logo = require('../../shared/logo.png')

const Nav: FC = () => {
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    
    useEffect(() => {
        const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
        setLastScrollTop(scrollTop);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);


    return (
        <div className={`${styles.logoWrapper} ${isVisible ? styles.visible : styles.hidden}`}>
            <div className={styles.logo}>
                <div className={styles.logoInline}>
                    {/* <LogoSVGComponent width='70' height='70' className={styles.svg}/> */}
                    <img src={logo} alt="" className={styles.svg} />
                    <div className={styles.logoText}>TJ Plumbing</div>
                </div>
                <div className={styles.callWrapper}>
                    <CallNow />
                </div>
            </div>
        </div>
    )
}

export default Nav;