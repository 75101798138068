import React, { FC } from 'react'
import ServiceItem from './ServiceItem';
import styles from './ServiceList.module.scss'

interface ServiceListProps {
    title: string,
    list: string[],
}

const ServiceList: FC<ServiceListProps> = ({ title, list }) => {
    return (
        <div className={styles.list}>
            <h3>{title}</h3>
            <div className={styles.map}>
                {list.map((item, id) => (
                    <ServiceItem key={id} item={item} />
                ))}
            </div>
        </div>
    )
}

export default ServiceList;