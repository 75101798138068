import React, { FC } from 'react'
import styles from './Footer.module.scss'

const Footer: FC = () => {
    return (
        <footer className={styles.footer}>
            © TJ Plumbing CA 2024, All Rights Reserved.
        </footer>
    )
}

export default Footer;