import React, { FC } from 'react'
import styles from './ReviewItem.module.scss'
import ReviewStars from './ReviewStars';

interface ReviewItemProps {
    name: string,
    img: string,
    stars: number,
    text: string,
    reviewURL: string,
}

const ReviewItem: FC<ReviewItemProps> = ({ 
    name,
    img,
    stars,
    text,
    reviewURL,
 }) => {
    return (
        <div className={styles.reviewWrapper}>
            <div className={styles.review}>
                <div className={styles.reviewBlock}>
                    <img src={img} alt={name} loading='lazy' />
                    <div className={styles.reviewName}>
                        {name}
                        <ReviewStars stars={stars} />
                    </div>
                </div>
                <p className={styles.reviewText}>{text}</p>
                <a href={reviewURL} target='_blank' rel="noreferrer">View on Google Maps
                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z" fill="black"/>
                    </svg>
                </a>
            </div>
        </div>
        
    )
}

export default ReviewItem;