import React, { FC } from 'react'
import styles from './About.module.scss'
import AboutBlock from './AboutBlock';

const img1 = require('../../shared/pic1About.webp')
const img2 = require('../../shared/pic2About.webp')
const img3 = require('../../shared/pic3About.webp')

const About: FC = () => {
    return (
        <div className={styles.about}>
            <h2 className={styles.titleAbout}>ABOUT US</h2>
            <div className={styles.aboutRow}>
                {/* <AboutBlock imageSrc={img1} /> */}
                <AboutBlock text='TJ PLUMBING is a family-owned business providing emergency plumbing, drain services, and residential plumbing services in Toronto, Ontario.' imageSrc={img1} />
                <AboutBlock text='Our professional experts offer reliable and efficient plumbing services to homeowners, local businesses and property managers throughout the Greater Toronto Area.' imageSrc={img2} />
                <AboutBlock text='Contact us for all your plumbing needs in Toronto and surrounding GTA, from repairs to installations, and experience our top-notch customer service and expertise.' imageSrc={img3} />
                
            </div>
        </div>
    )
}

export default About;