import React, { FC } from 'react'
import Slider from 'react-slick'
import styles from './Reviews.module.scss'
import ReviewItem from './ReviewItem'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const img1 = require('../../shared/reviewImg/1.webp')
const img2 = require('../../shared/reviewImg/2.webp')
const img3 = require('../../shared/reviewImg/3.webp')
const img4 = require('../../shared/reviewImg/4.webp')
const img5 = require('../../shared/reviewImg/5.webp')
const img6 = require('../../shared/reviewImg/6.webp')
const img7 = require('../../shared/reviewImg/7.webp')

const reviews = [
    {
        name: 'Soori Daudzai',
        img: img1,
        stars: 5,
        text: 'We found the service from TJ PLUMBING to be a pleasing experience and the technician very efficient.  The company is also very professional to deal with.   Fees are reasonable and our technician Joe was very informative, professional, extremely competent, and presented us with options for our issues.  If you have a toilet under a banjo countertop, call TJ PLUMBING and ask for Joe.',
        reviewURL: 'https://maps.app.goo.gl/4QBPhZP7QxSTuDV57'
    },
    {
        name: 'Babak Maghsoudloo',
        img: img2,
        stars: 5,
        text: 'had Joe come to our home to deal with a leaky toilet. this was in a basement in a very cramped area. i booked everything with him on text. he was ton time and was able to walk through and sort everything out within a couple of hours. great work, very clean, and honest worker. highly recommend!',
        reviewURL: 'https://maps.app.goo.gl/qk53DCKTbcVhtqRv6'
    },
    {
        name: 'Anand Gaurav',
        img: img3,
        stars: 5,
        text: 'Very prompt and professional service provided by TJ plumbing. I had to use their services couple (Once a clogged drain and peaky pipes, and another time for damaged sink)  of times and each time they saved me few bucks by actually fixing the problem and not just changing entire plumbing system. Very punctual and knowledgeable as well. I would definitely recommend them for any of your plumbing needs.',
        reviewURL: 'https://maps.app.goo.gl/bYUjiVEcCSJAPNrK8'   
    },
    {
        name: 'Mustafa Sherzai',
        img: img4,
        stars: 5,
        text: 'Jason did a great job, great service standards, will definitely use TJ Plumbing for all our plumbing services. Was able to help us out right away and no time was wasted to get the work done and provide us was great information and recommendations. Will recommend them to all we know for any plumbing services.',
        reviewURL: 'https://maps.app.goo.gl/3rptVLK16vH9xARV8'
    },
    {
        name: 'Biwei Tan Tan',
        img: img5,
        stars: 5,
        text: 'Exceptional service. Their team was incredibly professional and patient, dedicated to solving every issue to ensure everything worked seamlessly. Communication is truly outstanding, no long way, always rapid respond.',
        reviewURL: 'https://maps.app.goo.gl/eaCc3a4BrtzdYAv4A'
    },
    {
        name: 'Ben Cornish',
        img: img6,
        stars: 5,
        text: 'Great job fixing shower faucet and sink! Very helpful throughout process!',
        reviewURL: 'https://maps.app.goo.gl/e4xBrkJicAAhHbqk8'
    },
    {
        name: 'Soroush A',
        img: img7,
        stars: 5,
        text: 'Hired Zhasur to investigate a leak. He was very professional and informative and left the workspace very clean. Would definitely hire him again',
        reviewURL: 'https://maps.app.goo.gl/zJD5CKLQ8sQtTbf76'
    }
]

function NextArrowDesctop(props : any) {
    const { className, style, onClick } = props;
    return (
        <svg 
            className={className}
            style={{ ...style, display: "block", width: "21px", height: "43px" }}
            onClick={onClick}
            width="21" height="43" viewBox="0 0 21 43" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1 42L20 21.5L1 1" stroke="#3B8DCB"/>
        </svg> 
    );
}

function PrewArrowDesctop(props : any) {
    const { className, style, onClick } = props;
    return (
        <svg
            className={className}
            style={{ ...style, display: "block", width: "21px", height: "43px" }}
            onClick={onClick}
            width="21" height="43" viewBox="0 0 21 43" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M20 1L1 21.5L20 42" stroke="#3B8DCB"/>
        </svg>
    );
}

function NextArrowMobile(props : any) {
    const { className, style, onClick } = props;
    return (
        <svg 
            className={className}
            style={{ ...style, display: "block", width: "9px", height: "13px" }}
            onClick={onClick}
            width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1 11.8689L8 6.3689L1 0.868896" stroke="#3B8DCB"/>
        </svg> 
    );
}

function PrewArrowMobile(props : any) {
    const { className, style, onClick } = props;
    return (
        <svg
            className={className}
            style={{ ...style, display: "block", width: "9px", height: "13px" }}
            onClick={onClick}
            width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M8 0.868896L1 6.3689L8 11.8689" stroke="#3B8DCB"/>
        </svg>
    );
}

const Reviews: FC = () => {
    const settingsDesc = {
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 4,
        // autoplay: true,
        pauseOnFocus: true,
        nextArrow: <NextArrowDesctop />,
        prevArrow: <PrewArrowDesctop />
    };
    const settingsMobilec = {
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        // autoplay: true,
        pauseOnFocus: true,
        nextArrow: <NextArrowMobile />,
        prevArrow: <PrewArrowMobile />
    };
    return (
        <div className={styles.reviews}>
            <h2 className={styles.title}>Reviews</h2>
            <div className={styles.sliderWrapperDesctop}>
                <Slider {...settingsDesc} >
                    {reviews.map((item, id) => (
                        <ReviewItem key={id} name={item.name} img={item.img} stars={item.stars} text={item.text} reviewURL={item.reviewURL} />
                    ))}
                </Slider>
            </div>
            <div className={styles.sliderWrapperMobile}>
                <Slider {...settingsMobilec} >
                    {reviews.map((item, id) => (
                        <ReviewItem key={id} name={item.name} img={item.img} stars={item.stars} text={item.text} reviewURL={item.reviewURL} />
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default Reviews;