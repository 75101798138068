import React, { FC } from 'react'
import styles from './ReviewStars.module.scss'

interface ReviewStarsProps {
    stars: number
}

const ReviewStars: FC<ReviewStarsProps> = ({ stars }) => {
    return (
        <div className={styles.stars}>
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.9 0.100098L10.8982 6.24985L17.3644 6.24985L12.1331 10.0506L14.1313 16.2003L8.9 12.3996L3.66871 16.2003L5.66689 10.0506L0.435597 6.24985L6.90182 6.24985L8.9 0.100098Z" fill="#FBCA1B"/>
            </svg>
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.9 0.100098L10.8982 6.24985L17.3644 6.24985L12.1331 10.0506L14.1313 16.2003L8.9 12.3996L3.66871 16.2003L5.66689 10.0506L0.435597 6.24985L6.90182 6.24985L8.9 0.100098Z" fill="#FBCA1B"/>
            </svg>
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.9 0.100098L10.8982 6.24985L17.3644 6.24985L12.1331 10.0506L14.1313 16.2003L8.9 12.3996L3.66871 16.2003L5.66689 10.0506L0.435597 6.24985L6.90182 6.24985L8.9 0.100098Z" fill="#FBCA1B"/>
            </svg>
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.9 0.100098L10.8982 6.24985L17.3644 6.24985L12.1331 10.0506L14.1313 16.2003L8.9 12.3996L3.66871 16.2003L5.66689 10.0506L0.435597 6.24985L6.90182 6.24985L8.9 0.100098Z" fill="#FBCA1B"/>
            </svg>
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.9 0.100098L10.8982 6.24985L17.3644 6.24985L12.1331 10.0506L14.1313 16.2003L8.9 12.3996L3.66871 16.2003L5.66689 10.0506L0.435597 6.24985L6.90182 6.24985L8.9 0.100098Z" fill="#FBCA1B"/>
            </svg>
        </div>
    )
}

export default ReviewStars;