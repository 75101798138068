import React, { FC } from 'react'
import styles from './ServingItem.module.scss'

interface ServingItemProps {
    location: string,
}

const ServingItem: FC<ServingItemProps> = ({ location }) => {
    return (
        <div className={styles.servingItem}>
            <svg width="26" height="26" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26ZM17.8683 13.2047C17.8506 13.2271 17.8362 13.2522 16.0972 15.9747C17.9734 16.2621 21.1525 17.1167 21.1525 19.0008C21.1525 21.1527 16.9139 22.2542 13 22.2542C10.9068 22.2542 8.93097 21.961 7.4367 21.4286C5.29675 20.6662 4.84746 19.6872 4.84746 19.0008C4.84746 17.117 8.03057 16.2617 9.90276 15.9747L8.15159 13.2331C7.40917 12.2079 7.02147 10.9864 7.02147 9.74652C7.02147 6.45078 9.69768 3.74573 13 3.74573C16.3023 3.74573 18.9785 6.45621 18.9785 9.74652C18.9785 10.9872 18.5988 12.1776 17.8799 13.189C17.8732 13.1986 17.8707 13.2016 17.8683 13.2047ZM5.93446 19.0008C5.93446 19.9161 8.72481 21.1697 13 21.1697C17.3006 21.1697 20.0655 19.9081 20.0655 19.0008C20.0655 18.3859 18.6318 17.3744 15.4548 16.98L13.4584 20.1055C13.3587 20.2617 13.1859 20.3564 13 20.3564C12.8141 20.3564 12.6413 20.2617 12.5416 20.1055L10.5452 16.98C7.36822 17.3744 5.93446 18.3855 5.93446 19.0008Z" fill="#3B8DCB"/>
            </svg>
            {location}
        </div>
    )
}

export default ServingItem;