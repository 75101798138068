import React, { FC } from 'react'
import styles from './Header.module.scss'
import CallNow from '../Nav/CallNow/CallNow'

const Header: FC = () => {
    return (
        <header className={styles.header}>
            <div className={styles.headerWrapper}>
                <div className={styles.headerText}>
                    <h1 className={styles.h1}>PLUMBING IN TORONTO</h1>
                    <div className={styles.textMinor}>Professional Plumbers in Toronto ON</div>
                    <button className={styles.button}><a href='#contact_form' className={styles.a}>BOOK AN APPOINTMENT</a></button>
                    <div className={styles.callWrapper}>
                        <CallNow />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;